/* eslint-disable react-hooks/exhaustive-deps */
import React, { Children, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import ksuid from '@cuvva/ksuid';
import IconButton from '../button/IconButton';
import { CardActionsDropdownWrapper, CardDropdownMenu } from './atoms/CardHeaderGroupWrapper';
import * as Dropdown from '~lib/frontend/design-system/components/dropdown';
import useResizeObserver from '~lib/frontend/hooks/use-resize-observer';
const CardActionsDropdown = props => {
    const { maxWidth, loading, defaultCollapse, children } = props;
    const ref = useRef(null);
    const dropdownRef = useRef(null);
    const childrenCount = Children.count(children);
    const componentChildren = Children.toArray(children).reverse();
    const getChildrenIndex = useCallback(() => {
        const newArray = [];
        for (let i = 0; i < childrenCount; i++)
            newArray.push(i);
        return newArray;
    }, [childrenCount]);
    const [width] = useResizeObserver(ref);
    const [open, setOpen] = useState(false);
    const [visibleElements, setVisibleElements] = useState(getChildrenIndex());
    const [hiddenElements, setHiddenElements] = useState([]);
    useEffect(() => {
        if (defaultCollapse) {
            setVisibleElements([]);
            setHiddenElements(getChildrenIndex());
            return;
        }
        if (maxWidth === 0 || typeof width !== 'number')
            return;
        if (width > maxWidth && visibleElements.length > 0) {
            const lastVisibleElement = visibleElements[visibleElements.length - 1];
            setVisibleElements(prev => prev.slice(0, prev.length - 1));
            setHiddenElements(prev => [...prev, lastVisibleElement]);
        }
    }, [maxWidth, width]);
    useEffect(() => {
        if (defaultCollapse)
            return;
        if (maxWidth === 0 || typeof width !== 'number')
            return;
        if (width < maxWidth && hiddenElements.length > 0) {
            const lastHiddenElement = hiddenElements[hiddenElements.length - 1];
            setHiddenElements(hiddenElements.slice(0, hiddenElements.length - 1));
            setVisibleElements([...visibleElements, lastHiddenElement]);
        }
    }, [maxWidth]);
    if (!ref)
        return null;
    return (React.createElement(Fragment, null,
        React.createElement(CardActionsDropdownWrapper, { "$loading": loading, ref: ref },
            Boolean(hiddenElements.length) && (React.createElement(IconButton, { ref: dropdownRef, "$size": 'small', "$type": 'neutral', "$icon": 'ic_more', onClick: () => setOpen(!open) })),
            visibleElements.map(index => componentChildren[index]).reverse()),
        React.createElement(Dropdown.Menu, { shown: open, reference: dropdownRef, placement: 'bottom-end', onClose: () => setOpen(false) }, hiddenElements
            .map(index => {
            const element = componentChildren[index];
            if (!React.isValidElement(element))
                return void 0;
            const { props = {} } = element;
            const { as, onClick, icon, children, to } = props;
            const type = as ?? 'button';
            const key = element.key ?? ksuid.generate('opsnonce').toString();
            if (!children)
                return React.createElement(CardDropdownMenu, { key: key }, element);
            const handleClick = () => {
                if (type === 'button' && onClick)
                    onClick();
                setOpen(false); // Automatically close the dropdown
            };
            return (React.createElement(Dropdown.Item, { type: type, icon: icon, onClick: handleClick, to: type === 'link' && to, key: key }, children));
        })
            .reverse())));
};
export default CardActionsDropdown;
