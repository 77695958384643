import { all } from 'redux-saga/effects';
import deleteCarClub from './delete-car-club';
import getBorrower from './get-borrowers';
import getCarClub from './get-car-club';
import getLender from './get-lender';
import getLenderAccountStatus from './get-lender-account-status';
import getSharedQuoteLiveLocation from './get-share-quote-live-location';
import getSharedQuote from './get-shared-quote';
import getVehicleAvailability from './get-vehicle-availability';
import getVehicleRates from './get-vehicle-rates';
import getVehicles from './get-vehicles';
import listBorrowersPolicies from './list-borrowers-policies';
import listUserCarClubs from './list-user-car-clubs';
import listUserContentRevisions from './list-user-content-revisions';
import listVehicleCarClubs from './list-vehicle-car-clubs';
import listVehiclePhotos from './list-vehicle-photos';
import markUserContentInappropriate from './mark-user-content-inappropriate';
import removeCarClubMember from './remove-car-club-member';
import removeCarClubVehicle from './remove-car-club-vehicle';
import removeVehicleBorrowers from './remove-vehicle-borrowers';
import removeVehicleLender from './remove-vehicle-lender';
import removeVehiclePhotos from './remove-vehicle-photos';
import removeVehicleAvailability from './set-vehicle-availability';
import updateCarClubName from './update-car-club-name';
export default function* socialDrivingSaga() {
    yield all([
        getSharedQuote,
        getSharedQuoteLiveLocation,
        getVehicles,
        getLender,
        getBorrower,
        removeVehicleLender,
        removeVehicleBorrowers,
        getVehicleRates,
        listBorrowersPolicies,
        getLenderAccountStatus,
        getVehicleAvailability,
        removeVehicleAvailability,
        listUserContentRevisions,
        markUserContentInappropriate,
        listUserCarClubs,
        listVehicleCarClubs,
        listVehiclePhotos,
        removeCarClubMember,
        removeCarClubVehicle,
        removeVehiclePhotos,
        deleteCarClub,
        getCarClub,
        updateCarClubName,
    ]);
}
