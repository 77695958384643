import { all, fork } from 'redux-saga/effects';
import createFileFlag from './create-file-flag';
import fileById from './file-by-id';
import listByIdentifiers from './list-by-identifiers';
import uploadFile from './upload-file';
export default function* uploadSaga() {
    yield all([
        fork(fileById),
        fork(listByIdentifiers),
        fork(uploadFile),
        createFileFlag,
    ]);
}
