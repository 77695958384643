export const initialSocialDrivingState = {
    getSharedQuote: {},
    getSharedQuoteLiveLocation: {},
    getVehicles: {},
    getLender: {},
    getBorrower: {},
    removeVehicleLender: {},
    removeVehicleBorrowers: {},
    getVehicleRates: {},
    listBorrowerPolicies: {},
    getLenderAccountStatus: {},
    getVehicleAvailability: {},
    removeVehicleAvailability: {},
    listUserContentRevisions: {},
    markUserContentInappropriate: {},
    listUserCarClubs: {},
    listVehicleCarClubs: {},
    deleteCarClub: {},
    removeCarClubMember: {},
    removeCarClubVehicle: {},
    getCarClub: {},
    updateCarClubName: {},
    listVehiclePhotos: {},
    removeVehiclePhotos: {},
};
export const SocialDrivingActionTypes = {
    GET_SHARED_QUOTE: '@platform/social-driving/GET_SHARED_QUOTE',
    SHARED_QUOTE_LIVE_LOCATION: '@platform/social-driving/SHARED_QUOTE_LIVE_LOCATION',
    GET_VEHICLES: '@platform/social-driving/GET_VEHICLES',
    GET_LENDER: '@platform/social-driving/GET_LENDER',
    GET_BORROWERS: '@platform/social-driving/GET_BORROWERS',
    REMOVE_VEHICLE_LENDER: '@platform/social-driving/REMOVE_VEHICLE_LENDER',
    REMOVE_VEHICLE_BORROWERS: '@platform/social-driving/REMOVE_VEHICLE_BORROWERS',
    GET_VEHICLE_RATES: '@platform/social-driving/GET_VEHICLE_RATES',
    LIST_BORROWER_POLICIES: '@platform/social-driving/LIST_BORROWER_POLICIES',
    GET_LENDER_ACCOUNT_STATUS: '@platform/social-driving/GET_LENDER_ACCOUNT_STATUS',
    GET_VEHICLE_AVAILABILITY: '@platform/social-driving/GET_VEHICLE_AVAILABILITY',
    REMOVE_VEHICLE_AVAILABILITY: '@platform/social-driving/REMOVE_VEHICLE_AVAILABILITY',
    LIST_USER_CONTENT_REVISIONS: '@platform/social-driving/LIST_USER_CONTENT_REVISIONS',
    MARK_USER_CONTENT_INAPPROPRIATE: '@platform/social-driving/MARK_USER_CONTENT_INAPPROPRIATE',
    LIST_USER_CAR_CLUBS: '@platform/social-driving/LIST_USER_CAR_CLUBS',
    LIST_VEHICLE_CAR_CLUBS: '@platform/social-driving/LIST_VEHICLE_CAR_CLUBS',
    DELETE_CAR_CLUB: '@platform/social-driving/DELETE_CAR_CLUB',
    REMOVE_CAR_CLUB_MEMBER: '@platform/social-driving/REMOVE_CAR_CLUB_MEMBER',
    REMOVE_CAR_CLUB_VEHICLE: '@platform/social-driving/REMOVE_CAR_CLUB_VEHICLE',
    GET_CAR_CLUB: '@platform/social-driving/GET_CAR_CLUB',
    UPDATE_CAR_CLUB_NAME: '@platform/social-driving/UPDATE_CAR_CLUB_NAME',
    LIST_VEHICLE_PHOTOS: '@platform/social-driving/LIST_VEHICLE_PHOTOS',
    REMOVE_VEHICLE_PHOTOS: '@platform/social-driving/REMOVE_VEHICLE_PHOTOS',
};
