import { all } from 'redux-saga/effects';
import declareLicense from './declare-license';
import declareTestPass from './declare-test-pass';
import licenseByUserId from './license-by-user-id';
import listMockedLicenses from './list-mocked-licenses';
import removeLicense from './remove-license';
import removeTestPass from './remove-test-pass';
import search from './search';
import updateLicense from './update-license';
import updateLicenseHint from './update-license-hint';
export default function* drivingLicenseRegistrationSaga() {
    yield all([
        declareLicense,
        declareTestPass,
        licenseByUserId,
        listMockedLicenses,
        removeLicense,
        removeTestPass,
        search,
        updateLicense,
        updateLicenseHint,
    ]);
}
