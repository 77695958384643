import { DrivingLicenseRegistrationActionTypes, } from './types';
import { createAsyncAction, createAsyncMapAction } from '~lib/shared/redux/actions';
export const licenseByUserId = createAsyncMapAction(DrivingLicenseRegistrationActionTypes.LICENSE_BY_USER_ID, p => [p.userId, p.revision].filter(Boolean).join('@'));
export const updateLicenseHint = createAsyncMapAction(DrivingLicenseRegistrationActionTypes.UPDATE_LICENSE_HINT, p => p.userId);
export const declareLicense = createAsyncMapAction(DrivingLicenseRegistrationActionTypes.DECLARE_LICENSE, p => p.requestId);
export const declareTestPass = createAsyncMapAction(DrivingLicenseRegistrationActionTypes.DECLARE_TEST_PASS, p => p.requestId);
export const removeLicense = createAsyncMapAction(DrivingLicenseRegistrationActionTypes.REMOVE_LICENSE, p => p.requestId);
export const removeTestPass = createAsyncMapAction(DrivingLicenseRegistrationActionTypes.REMOVE_TEST_PASS, p => p.requestId);
export const updateLicense = createAsyncMapAction(DrivingLicenseRegistrationActionTypes.UPDATE_LICENSE, p => p.requestId);
export const listMockedLicenses = createAsyncAction(DrivingLicenseRegistrationActionTypes.LIST_MOCKED_LICENSES);
export const search = createAsyncMapAction(DrivingLicenseRegistrationActionTypes.SEARCH, p => p.requestId);
