import React from 'react';
import { IconWrap, Wrap } from '../atoms/item';
import { Anchor } from '~lib/frontend/atoms';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
export const Item = ({ icon, disabled, children, ...action }) => (React.createElement(Wrap, { as: action.type === 'link' && !disabled ? Anchor : void 0, to: action.type === 'link' && !disabled ? action.to : void 0, onClick: action.type === 'button' && !disabled ? action.onClick : void 0, disabled: disabled },
    icon && (React.createElement(IconWrap, { disabled: disabled },
        React.createElement(Icon, { icon: icon, "$size": '16px', "$color": disabled ? 'neutralFillMinorMuted' : 'primaryFill' }))),
    React.createElement(Typography, { "$type": 'Label.Small', "$color": disabled ? 'textMinorMuted' : 'textOnSurfaceBackground' }, children)));
export default Item;
