import { combineReducers } from 'redux';
import { DrivingLicenseRegistrationActionTypes, initialDrivingLicenseRegistrationState } from './types';
import createAsyncMapReducer from '~lib/shared/redux/reducers/async-map-reducer';
import createAsyncReducer from '~lib/shared/redux/reducers/async-reducer';
export const declareLicense = createAsyncMapReducer(DrivingLicenseRegistrationActionTypes.DECLARE_LICENSE, initialDrivingLicenseRegistrationState.declareLicense);
export const declareTestPass = createAsyncMapReducer(DrivingLicenseRegistrationActionTypes.DECLARE_TEST_PASS, initialDrivingLicenseRegistrationState.declareTestPass);
export const licenseByUserId = createAsyncMapReducer(DrivingLicenseRegistrationActionTypes.LICENSE_BY_USER_ID, initialDrivingLicenseRegistrationState.licenseByUserId);
export const listMockedLicenses = createAsyncReducer(DrivingLicenseRegistrationActionTypes.LIST_MOCKED_LICENSES, initialDrivingLicenseRegistrationState.listMockedLicenses);
export const removeLicense = createAsyncMapReducer(DrivingLicenseRegistrationActionTypes.REMOVE_LICENSE, initialDrivingLicenseRegistrationState.removeLicense);
export const removeTestPass = createAsyncMapReducer(DrivingLicenseRegistrationActionTypes.REMOVE_TEST_PASS, initialDrivingLicenseRegistrationState.removeTestPass);
export const search = createAsyncMapReducer(DrivingLicenseRegistrationActionTypes.SEARCH, initialDrivingLicenseRegistrationState.search);
export const updateLicenseHint = createAsyncMapReducer(DrivingLicenseRegistrationActionTypes.UPDATE_LICENSE_HINT, initialDrivingLicenseRegistrationState.updateLicenseHint);
export const updateLicense = createAsyncMapReducer(DrivingLicenseRegistrationActionTypes.UPDATE_LICENSE, initialDrivingLicenseRegistrationState.updateLicense);
export default combineReducers({
    declareLicense,
    declareTestPass,
    licenseByUserId,
    listMockedLicenses,
    removeLicense,
    removeTestPass,
    search,
    updateLicenseHint,
    updateLicense,
});
