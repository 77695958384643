export const initialUploadState = {
    fileById: {},
    listByIdentifiers: {},
    uploadFile: {},
    createFileFlag: {},
};
export const UploadActionTypes = {
    FILE_BY_ID: '@@platform/upload/FILE_BY_ID',
    FILE_BY_ID_SUCCESS: '@@platform/upload/FILE_BY_ID_SUCCESS',
    FILE_BY_ID_FAILURE: '@@platform/upload/FILE_BY_ID_FAILURE',
    LIST_BY_IDENTIFIERS: '@@platform/upload/LIST_BY_IDENTIFIERS',
    LIST_BY_IDENTIFIERS_SUCCESS: '@@platform/upload/LIST_BY_IDENTIFIERS_SUCCESS',
    LIST_BY_IDENTIFIERS_FAILURE: '@@platform/upload/LIST_BY_IDENTIFIERS_FAILURE',
    UPLOAD_FILE: '@@platform/upload/UPLOAD_FILE',
    UPLOAD_FILE_SUCCESS: '@@platform/upload/UPLOAD_FILE_SUCCESS',
    UPLOAD_FILE_FAILURE: '@@platform/upload/UPLOAD_FILE_FAILURE',
    CREATE_FILE_FLAG: '@@platform/upload/CREATE_FILE_FLAG',
};
