import React from 'react';
import { Helmet } from 'react-helmet-async';

import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';

interface MetaProps {
	get: (key: string, fallback?: string) => string;
}

const Meta: React.FCWithChildren<MetaProps> = ({ get, children }) => {
	const fbImage = useDesignSystemAsset({ type: 'share', value: get('fb_image', 'default_head.png') });
	const twitterImage = useDesignSystemAsset({ type: 'share', value: get('twitter_image', 'default_head.png') });
	const robots = get('robots');
	const noIndex = get('noindex');

	return (
		<Helmet defer={false}>
			<title>{get('title')}</title>
			<meta name={'description'} content={get('description')} />
			<meta property={'og:locale'} content={'en_GB'} />
			<meta property={'og:type'} content={'website'} />
			<meta property={'og:title'} content={get('social_title')} />
			<meta property={'og:description'} content={get('social_description')} />
			<meta property={'og:url'} content={get('canonical_url')} />
			<meta property={'og:site_name'} content={'Cuvva'} />
			<meta property={'og:image'} content={fbImage} />
			<meta property={'og:image:secure_url'} content={fbImage} />
			<meta property={'og:image:width'} content={'1200'} />
			<meta property={'og:image:height'} content={'1200'} />
			<meta property={'og:image:alt'} content={get('social_title')} />
			<meta name={'twitter:card'} content={'summary_large_image'} />
			<meta name={'twitter:title'} content={get('social_title')} />
			<meta name={'twitter:image'} content={twitterImage} />
			<meta name={'twitter:description'} content={get('social_description')} />
			<meta name={'twitter:site'} content={'@Cuvva'} />
			<meta name={'twitter:creator'} content={'@cuvva'} />
			<link rel={'canonical'} href={get('canonical_url')} />

			{Boolean(get('schema')) && (
				<script type={'application/ld+json'}>
					{JSON.stringify(get('schema'))}
				</script>
			)}

			<meta name={'robots'} content={getRobotsMeta(noIndex, robots)} />
			{children}
		</Helmet>
	);
};

const getRobotsMeta = (noIndex: string, robots: string) => {
	if (robots.length > 0)
		return robots;
	if (noIndex)
		return 'noindex, follow';

	return 'index, follow';
};

export default Meta;
