const service = 'service-social-driving';
const version20231107 = '1/2023-11-07';
const version20240606 = '1/2024-06-06';
export const getSharedQuote = (client, req) => client.rpc('service-social-driving', '1/latest/get_shared_quote', req);
export const sharedQuoteLiveLocation = (client, req) => client.rpc('service-social-driving', '1/latest/get_shared_quote_live_location', req);
export const getVehicles = (api, payload) => api.rpc(service, '1/2022-11-08/get_vehicles', payload);
export const getLender = (api, payload) => api.rpc(service, '1/2022-11-08/get_lender', payload);
export const getBorrower = (api, payload) => api.rpc(service, '1/2022-11-08/get_borrower', payload);
export const removeVehicleLender = (api, payload) => api.rpc(service, '1/2022-11-08/remove_vehicle_lender', payload);
export const removeVehicleBorrowers = (api, payload) => api.rpc(service, '1/2022-11-08/remove_vehicle_borrowers', payload);
export const getVehicleRates = (api, payload) => api.rpc(service, '1/2022-11-08/get_vehicle_rates', payload);
export const listBorrowerPolicies = (api, payload) => api.rpc(service, '1/2022-11-08/list_borrower_policies', payload);
export const getLenderAccountStatus = (api, payload) => api.rpc(service, '1/2022-11-08/get_lender_account_status', payload);
export const getVehicleAvailability = (api, payload) => api.rpc(service, '1/2023-09-19/get_vehicle_availability', payload);
export const removeVehicleAvailability = (api, payload) => api.rpc(service, '1/2023-09-19/remove_vehicle_availability', payload);
export const listUserContentRevisions = (api, payload) => api.rpc(service, '1/2022-11-08/list_user_content_revisions', payload);
export const markUserContentInappropriate = (api, payload) => api.rpc(service, '1/2022-11-08/mark_user_content_inappropriate', payload);
// Car clubs
export const listUserCarClubs = (api, payload) => api.rpc(service, `${version20231107}/list_users_car_clubs_ops`, payload);
export const listVehicleCarClubs = (api, payload) => api.rpc(service, `${version20231107}/list_vehicle_car_clubs`, payload);
export const deleteCarClub = (api, payload) => api.rpc(service, `${version20231107}/delete_car_club`, payload);
export const removeCarClubMember = (api, payload) => api.rpc(service, `${version20231107}/remove_car_club_member`, payload);
export const removeCarClubVehicle = (api, payload) => api.rpc(service, `${version20231107}/remove_car_club_vehicle`, payload);
export const getCarClub = (api, payload) => api.rpc(service, `${version20231107}/get_car_club_ops`, payload);
export const updateCarClubName = (api, payload) => api.rpc(service, `${version20231107}/update_car_club_name`, payload);
export const listVehiclePhotos = (api, payload) => api.rpc(service, `${version20231107}/list_vehicles_photos`, payload);
export const removeVehiclePhotos = (api, payload) => api.rpc(service, `${version20240606}/remove_vehicle_photos`, payload);
