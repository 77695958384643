import { combineReducers } from 'redux';
import { initialSocialDrivingState, SocialDrivingActionTypes } from './types';
import createAsyncMapReducer from '~lib/shared/redux/reducers/async-map-reducer';
export const getSharedQuote = createAsyncMapReducer(SocialDrivingActionTypes.GET_SHARED_QUOTE, initialSocialDrivingState.getSharedQuote);
export const getSharedQuoteLiveLocation = createAsyncMapReducer(SocialDrivingActionTypes.SHARED_QUOTE_LIVE_LOCATION, initialSocialDrivingState.getSharedQuoteLiveLocation);
export const getVehicles = createAsyncMapReducer(SocialDrivingActionTypes.GET_VEHICLES, initialSocialDrivingState.getVehicles);
export const getLender = createAsyncMapReducer(SocialDrivingActionTypes.GET_LENDER, initialSocialDrivingState.getLender);
export const getBorrower = createAsyncMapReducer(SocialDrivingActionTypes.GET_BORROWERS, initialSocialDrivingState.getBorrower);
export const removeVehicleLender = createAsyncMapReducer(SocialDrivingActionTypes.REMOVE_VEHICLE_LENDER, initialSocialDrivingState.removeVehicleLender);
export const removeVehicleBorrowers = createAsyncMapReducer(SocialDrivingActionTypes.REMOVE_VEHICLE_BORROWERS, initialSocialDrivingState.removeVehicleBorrowers);
export const getVehicleRates = createAsyncMapReducer(SocialDrivingActionTypes.GET_VEHICLE_RATES, initialSocialDrivingState.getVehicleRates);
export const listBorrowerPolicies = createAsyncMapReducer(SocialDrivingActionTypes.LIST_BORROWER_POLICIES, initialSocialDrivingState.listBorrowerPolicies);
export const getLenderAccountStatus = createAsyncMapReducer(SocialDrivingActionTypes.GET_LENDER_ACCOUNT_STATUS, initialSocialDrivingState.getLenderAccountStatus);
export const getVehicleAvailability = createAsyncMapReducer(SocialDrivingActionTypes.GET_VEHICLE_AVAILABILITY, initialSocialDrivingState.getVehicleAvailability);
export const removeVehicleAvailability = createAsyncMapReducer(SocialDrivingActionTypes.REMOVE_VEHICLE_AVAILABILITY, initialSocialDrivingState.removeVehicleAvailability);
export const listUserContentRevisions = createAsyncMapReducer(SocialDrivingActionTypes.LIST_USER_CONTENT_REVISIONS, initialSocialDrivingState.listUserContentRevisions);
export const markUserContentInappropriate = createAsyncMapReducer(SocialDrivingActionTypes.MARK_USER_CONTENT_INAPPROPRIATE, initialSocialDrivingState.markUserContentInappropriate);
export const listUserCarClubs = createAsyncMapReducer(SocialDrivingActionTypes.LIST_USER_CAR_CLUBS, initialSocialDrivingState.listUserCarClubs);
export const listVehicleCarClubs = createAsyncMapReducer(SocialDrivingActionTypes.LIST_VEHICLE_CAR_CLUBS, initialSocialDrivingState.listVehicleCarClubs);
export const getCarClub = createAsyncMapReducer(SocialDrivingActionTypes.GET_CAR_CLUB, initialSocialDrivingState.getCarClub);
export const deleteCarClub = createAsyncMapReducer(SocialDrivingActionTypes.DELETE_CAR_CLUB, initialSocialDrivingState.deleteCarClub);
export const removeCarClubMember = createAsyncMapReducer(SocialDrivingActionTypes.REMOVE_CAR_CLUB_MEMBER, initialSocialDrivingState.removeCarClubMember);
export const removeCarClubVehicle = createAsyncMapReducer(SocialDrivingActionTypes.REMOVE_CAR_CLUB_VEHICLE, initialSocialDrivingState.removeCarClubVehicle);
export const updateCarClubName = createAsyncMapReducer(SocialDrivingActionTypes.UPDATE_CAR_CLUB_NAME, initialSocialDrivingState.updateCarClubName);
export const listVehiclePhotos = createAsyncMapReducer(SocialDrivingActionTypes.LIST_VEHICLE_PHOTOS, initialSocialDrivingState.listVehiclePhotos);
export const removeVehiclePhotos = createAsyncMapReducer(SocialDrivingActionTypes.REMOVE_VEHICLE_PHOTOS, initialSocialDrivingState.removeVehiclePhotos);
export default combineReducers({
    getSharedQuote,
    getSharedQuoteLiveLocation,
    getVehicles,
    getLender,
    getBorrower,
    removeVehicleLender,
    removeVehicleBorrowers,
    getVehicleRates,
    listBorrowerPolicies,
    getLenderAccountStatus,
    getVehicleAvailability,
    removeVehicleAvailability,
    listUserContentRevisions,
    markUserContentInappropriate,
    listUserCarClubs,
    listVehicleCarClubs,
    deleteCarClub,
    removeCarClubMember,
    removeCarClubVehicle,
    getCarClub,
    updateCarClubName,
    listVehiclePhotos,
    removeVehiclePhotos,
});
